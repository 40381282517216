import { graphql, useStaticQuery, Link } from "gatsby"
import React, { useState } from "react"
import logo_light from "src/images/logo_light.svg"
import logo_dark from "src/images/logo_dark.svg"

export default function Header(props) {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          header {
            menuItems {
              link
              title
            }
          }
        }
      }
    }
  `)

  const [menuOpen, setMenuOpen] = useState(false)
  const theme = props.heroStyle === "dark" && !menuOpen ? "light" : "dark"
  const logo = theme === "light" ? logo_light : logo_dark
  const textColorClass = theme === "light" ? "text-white" : "text-gray-700"
  function toggleMenuVisibility() {
    setMenuOpen(!menuOpen)
  }

  const menuBlock = data.site.siteMetadata.header.menuItems.map(
    ({ link, title }) => (
      <Link to={link} className={`text-lg hover:text-nami-red ${textColorClass}`}>
        {title}
      </Link>
    )
  )

  return (
    <div
      className={`z-20 inset-0 flex flex-col py-2 sm:pb-0 h-16 ${
        menuOpen ? "bg-nami-gray fixed" : "absolute "
      } ${textColorClass}`}
    >
      <div className="flex items-center justify-between sm:justify-start px-2 sm:px-8">
        <div className="sm:hidden">
          {/* <!-- Mobile menu button--> */}
          <button
            type="button"
            className={`inline-flex items-center justify-center p-2 rounded-md ${textColorClass} text-opacity-60 hover:text-opacity-100`}
            aria-controls="mobile-menu"
            aria-expanded="false"
            onClick={toggleMenuVisibility}
          >
            <span className="sr-only">Open main menu</span>
            {/* <!-- Icon when menu is closed. -->
            <!--
              Heroicon name: outline/menu

              Menu open: "hidden", Menu closed: "block"
            --> */}
            <svg
              className={`${menuOpen ? "hidden" : "block"} block h-6 w-6`}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
            {/* <!-- Icon when menu is open. -->
            <!--
              Heroicon name: outline/x

              Menu open: "block", Menu closed: "hidden"
            --> */}
            <svg
              className={`${menuOpen ? "block" : "hidden"} h-6 w-6`}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <Link to="/#" onClick={props.onLogoClick}>
          <img className="h-8 w-auto" src={logo} alt="Nami" />
        </Link>
        <div className="hidden sm:block flex flex-row space-x-16 px-16 pt-3">
          {menuBlock}
        </div>
        <div className="block sm:hidden w-10" />
        {/* <!-- Mobile menu, show/hide based on menu state. --> */}
      </div>
      <div
        className={`${menuOpen ? "block" : "hidden"} sm:hidden `}
        id="mobile-menu"
      >
        <div className="pt-12 pb-3 px-8 h-screen w-full fixed flex flex-col justify-between bg-nami-gray">
          <div className="flex flex-col space-y-6 ">{menuBlock}</div>
        </div>
      </div>
    </div>
  )
}
