import React from "react"
import { graphql, useStaticQuery } from "gatsby"

export default function SocialBlock() {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          footer {
            social {
              iconName
              altName
              link
            }
          }
        }
      }
    }
  `)

  return (
    <div className="flex flex-row space-x-5">
      {data.site.siteMetadata.footer.social.map((item, index) => (
        <div key={index}>
          <a href={item.link}>
            <img
              className="h-5"
              src={
                require("src/images/common/footer/" + item.iconName + ".svg")
                  .default
              }
              alt={item.altName}
            />
          </a>
        </div>
      ))}
    </div>
  )
}
