import React from "react";
import ResponsiveGatsbyImage from "src/components/common/responsive_image";

export default function FullScreenImageWithText(props) {
  return (
    <div className="overflow-hidden">
      <div className="relative">
        <ResponsiveGatsbyImage
          alt={props.image_alt}
          files={props.files}
          image={props.image_name}
        />
        <div className="absolute inset-0 flex items-center justify-center">
          <span
            className={`${
              props.style === "light" ? "text-gray-700" : "text-white"
            } text-3xl lg:text-5xl text-center leading-relaxed lg:leading-relaxed xl:text-6xl xl:leading-relaxed`}
            dangerouslySetInnerHTML={{__html: props.title}}
          />
        </div>
      </div>
    </div>
  );
}
