import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import SocialBlock from "src/components/common/social_block";

export default function Footer() {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          footer {
            copyright
          }
        }
      }
    }
  `);

  return (
    <div className="px-12 md:py-4 bg-footer-blue flex flex-col md:flex-row md:items-end items-center justify-between">
      <div className="block pt-6 md:hidden">
        <SocialBlock />
      </div>
      <div className="text-white pt-6 pb-4 md:py-0">
        {data.site.siteMetadata.footer.copyright}
        <a className="text-white pl-4" href="/privacynotice.html">
          Privacy notice
        </a>
      </div>
      <div className="hidden md:block">
        <SocialBlock />
      </div>
    </div>
  );
}
