import {GatsbyImage} from "gatsby-plugin-image";
import React from "react";

export default function ResponsiveGatsbyImage(props) {
  return (
    <>
      <div className={`hidden md:block ${props.className}`}>
        <GatsbyImage
          alt={props.alt}
          image={
            props.files.find((el) => el.node.name === props.image + "_desktop")
              .node.childImageSharp.gatsbyImageData
          }
          fadeIn={props.fadeIn}
          className={props.desktopImageClassName}
        />
      </div>
      <div className={`block md:hidden ${props.className}`}>
        <GatsbyImage
          alt={props.alt}
          image={
            props.files.find((el) => el.node.name === props.image + "_mobile")
              .node.childImageSharp.gatsbyImageData
          }
          fadeIn={props.fadeIn}
          className={props.mobileImageClassName}
        />
      </div>
    </>
  );
}
