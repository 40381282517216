import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Helmet } from "react-helmet"

export default function CommonHelmet() {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  return (
    <Helmet>
      <meta property="og:title" content={data.site.siteMetadata.title}/>
      <meta property="og:image" content={require("../../images/meta.jpg").default}/>
      <meta property="og:description" content={data.site.siteMetadata.description}/>
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://nami.ai"/>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content={data.site.siteMetadata.description}
      />
      <title>{data.site.siteMetadata.title}</title>
      <link rel="canonical" href="https://nami.ai" />
      <html lang="en" />
    </Helmet>
  )
}
